import injected from '@snapshot-labs/lock/connectors/injected';
import trustwallet from '@snapshot-labs/lock/connectors/injected';
import portis from '@snapshot-labs/lock/connectors/portis';
import walletconnect from '@snapshot-labs/lock/connectors/walletconnect';
import configuredConnectors from '@/constants/connectors.json';
import type LockConnector from '@snapshot-labs/lock/src/connector';

interface LockConnectorConfig {
  key: string;
  connector: LockConnector;
  options: Record<string, unknown>;
}

const lockConnectorsMapping = {
  injected,
  walletconnect,
  trustwallet,
  portis,
};

const connectors = Object.entries(configuredConnectors).map(
  ([key, connectorConfig]) => ({
    key,
    connector: lockConnectorsMapping[key],
    options: connectorConfig.options,
  }),
);

export const walletsOptions: { connectors: LockConnectorConfig[] } = {
  connectors,
};
