import { useLayout } from '@/composables/core/useLayout';
import { computed, ComputedRef, Ref, unref } from 'vue';
const { isMobile, isTablet } = useLayout();

export function useFormattedAddress(
  address: Ref<string> | string,
  options: {
    flexSlicing?: boolean;
    headLength?: number;
  } = {},
): ComputedRef<string> {
  if (options.headLength) {
    return computed(
      () =>
        unref(address).slice(0, options.headLength) +
        '...' +
        unref(address).slice(-4),
    );
  }

  if (!options.flexSlicing) {
    return computed(
      () => unref(address).slice(0, 16) + '...' + unref(address).slice(-4),
    );
  }

  return computed(() => {
    const formatWalletTail = '...' + unref(address).slice(-4);
    if (isMobile.value) {
      return unref(address).slice(0, 5) + formatWalletTail;
    }
    if (isTablet.value) {
      return unref(address).slice(0, 12) + formatWalletTail;
    }

    return unref(address).slice(0, 16) + formatWalletTail;
  });
}
