import { fromWei } from '@/helpers/blockchain-utils';
import BigNumber from 'bignumber.js';

export class TokenAmount {
  public readonly weiAmount: BigNumber;

  public get relativeAmount(): BigNumber {
    return fromWei(this.weiAmount, this.decimals);
  }

  public get weiAmountString(): string {
    return this.weiAmount.toFixed(0);
  }

  public get relativeAmountString(): string {
    return this.relativeAmount.toFixed();
  }

  constructor(
    weiAmount: string | number | BigNumber,
    private readonly decimals = 18,
  ) {
    this.weiAmount = new BigNumber(weiAmount);
  }
}
