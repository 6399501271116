<template>
  <div
    class="flex footer-menu justify-content-between sm:justify-content-start"
  >
    <template v-for="(item, index) in items" :key="index">
      <div v-if="item.visible" class="sm:mr-s6 xl:mr-s8">
        <div class="caption uppercase">
          {{ item.label }}
        </div>
        <Menu :model="item.items">
          <template #item="{ item }">
            <div class="pt-s4 xl:pt-s3">
              <a
                class="menuitem-link"
                role="button"
                :href="item.url"
                target="_blank"
              >
                {{ item.label }}
              </a>
            </div>
          </template>
        </Menu>
      </div>
    </template>
  </div>
</template>

<script setup>
import { EXTERNAL_LINKS, SOCIAL_LINKS } from '@/constants/EXTERNAL_LINKS';

const items = [
  {
    label: 'About',
    visible: true,
    items: [
      {
        label: 'About Blueshift',
        url: EXTERNAL_LINKS.BLUESHIFT_ABOUT,
        visible: true,
      },
      {
        label: 'Whitepaper',
        url: EXTERNAL_LINKS.WHITEPAPER,
        visible: true,
      },
    ],
  },
  {
    label: 'Community',
    visible: true,
    items: [
      {
        label: 'Telegram (Off. group)',
        visible: true,
        url: EXTERNAL_LINKS.TELEGRAM_OFF_GROUP,
      },
      {
        label: 'Telegram (Сhat)',
        visible: true,
        url: EXTERNAL_LINKS.TELEGRAM_CHAT,
      },
      {
        label: 'Twitter',
        visible: true,
        url: SOCIAL_LINKS.TWITTER,
      },
      {
        label: 'Gitbook',
        visible: false,
        url: EXTERNAL_LINKS.GITBOOK,
      },
    ],
  },
  {
    label: 'Support',
    visible: true,
    items: [
      {
        label: 'FAQ',
        visible: false,
        url: EXTERNAL_LINKS.BLUESHIFT_ABOUT,
      },
      {
        label: 'E-mail',
        url: SOCIAL_LINKS.MAIL,
      },
    ],
  },
];
</script>

<style lang="scss">
.footer-menu {
  .tablet & {
    min-width: 50%;
  }
  .p-menuitem .menuitem-link {
    font-size: $font-size-sm;
    line-height: 1.3em;
    &:hover {
      text-decoration: underline;
    }
  }
  .mobile & {
    width: 100% !important;
    justify-content: space-between;
  }
}
.menuitem-link {
  font-size: $font-size-sm;
  line-height: 1.3em;
  cursor: pointer;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
}
</style>
