<template>
  <div class="inline-flex justify-content-center align-items-center text-info">
    <svg
      id="spinner-star"
      fill="currentColor"
      style="width: 20px; height: 20px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.70946 1.20946C8.70946 0.804056 8.4054 0.5 7.99999 0.5C7.59458 0.5 7.29053 0.804056 7.29053 1.20946V4.25003C7.29053 4.65543 7.59458 4.95949 7.99999 4.95949C8.4054 4.95949 8.70946 4.65543 8.70946 4.25003V1.20946Z"
      />
      <path
        d="M10.635 6.04161C10.8039 6.04161 11.0066 5.97405 11.1418 5.83891L13.2702 3.71052C13.5404 3.44025 13.5404 3.00105 13.2702 2.697C12.9999 2.42673 12.5607 2.42673 12.2566 2.697L10.1282 4.82539C9.85797 5.09566 9.85797 5.53485 10.1282 5.83891C10.2972 5.97405 10.4661 6.04161 10.635 6.04161Z"
      />
      <path
        d="M15.5 8.00024C15.5 7.59483 15.196 7.29077 14.7906 7.29077H11.75C11.3446 7.29077 11.0405 7.59483 11.0405 8.00024C11.0405 8.40564 11.3446 8.7097 11.75 8.7097H14.7906C15.1622 8.7097 15.5 8.40564 15.5 8.00024Z"
      />
      <path
        d="M10.162 11.1416L12.2904 13.27C12.4255 13.4051 12.5945 13.4727 12.7972 13.4727C12.9661 13.4727 13.1688 13.4051 13.3039 13.27C13.5742 12.9997 13.5742 12.5605 13.3039 12.2565L11.1755 10.1281C10.9053 9.85782 10.4661 9.85782 10.162 10.1281C9.85795 10.4321 9.85795 10.8713 10.162 11.1416Z"
      />
      <path
        d="M7.99999 11.0414C7.59458 11.0414 7.29053 11.3455 7.29053 11.7509V14.7915C7.29053 15.1969 7.59458 15.5009 7.99999 15.5009C8.4054 15.5009 8.70946 15.1969 8.70946 14.7915V11.7509C8.70946 11.3455 8.4054 11.0414 7.99999 11.0414Z"
      />
      <path
        d="M3.20263 13.5071C3.37155 13.5071 3.57425 13.4395 3.70939 13.3044L5.83778 11.176C6.10805 10.9057 6.10805 10.4665 5.83778 10.1625C5.56751 9.89221 5.12832 9.89221 4.82426 10.1625L2.69587 12.2909C2.4256 12.5611 2.4256 13.0003 2.69587 13.3044C2.831 13.4395 3.03371 13.5071 3.20263 13.5071Z"
      />
      <path
        d="M4.95949 8.00024C4.95949 7.59483 4.65543 7.29077 4.25003 7.29077H1.20946C0.804056 7.29077 0.5 7.59483 0.5 8.00024C0.5 8.40564 0.804056 8.7097 1.20946 8.7097H4.25003C4.65543 8.7097 4.95949 8.40564 4.95949 8.00024Z"
      />
      <path
        d="M5.83778 4.85832L3.70939 2.72993C3.43912 2.45965 2.99992 2.45965 2.69587 2.72993C2.4256 3.0002 2.4256 3.43939 2.69587 3.74345L4.82426 5.87184C4.9594 6.00697 5.12832 6.07454 5.33102 6.07454C5.53372 6.07454 5.70264 6.00697 5.83778 5.87184C6.14184 5.56778 6.14184 5.12859 5.83778 4.85832Z"
      />
    </svg>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
#spinner-star {
  path {
    animation: fade 1.4s linear infinite;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      animation-delay: 0.8s;
    }

    &:nth-child(6) {
      animation-delay: 1s;
    }

    &:nth-child(7) {
      animation-delay: 1.2s;
    }

    &:nth-child(8) {
      animation-delay: 1.4s;
    }
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
    animation-timing-function: ease-in;
  }
  100% {
    opacity: 0;
    animation-timing-function: ease-out;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
    animation-timing-function: ease-in;
  }
  100% {
    opacity: 0;
    animation-timing-function: ease-out;
  }
}
</style>
