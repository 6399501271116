import { useWallet } from '@/composables/core/useWallet';
import { computed, ComputedRef, Ref } from 'vue';

const { network } = useWallet();

export function useExplorerLink(
  hash: Ref<string>,
  type: 'tx' | 'address' | 'token' | 'token-transfers' = 'address',
): ComputedRef<string> {
  return computed(() => getExplorerLink(hash.value, type));
}

export function getExplorerLink(
  hash: string,
  type: 'tx' | 'address' | 'token' | 'token-transfers' = 'address',
) {
  if (!network.value || !network.value) {
    return '';
  }

  if (type === 'token-transfers') {
    return `${network.value.explorer}/token/${hash}/token-transfers`;
  }

  return `${network.value.explorer}/${type}/${hash}`;
}
