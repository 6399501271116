import { onBeforeUnmount, onMounted, ref } from 'vue';

export function useInterval(callBack: () => void, timeout: number): void {
  const intervalId = ref();

  onMounted(() => (intervalId.value = setInterval(callBack, timeout)));
  onBeforeUnmount(() => {
    clearTimeout(intervalId.value);
    intervalId.value = null;
  });
}
