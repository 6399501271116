export const EXTERNAL_LINKS: { [key: string]: string } = {
  BLUESHIFT_ABOUT: 'https://blueshift.fi',
  BLUESHIFT_APP: 'https://app.blueshift.fi',
  WHITEPAPER: 'https://blueshift.fi/pdf/blueshift-whitepaper.pdf',
  CAREERS: 'https://angel.co/company/blueshiftfi',
  TELEGRAM_OFF_GROUP: 'https://t.me/blueshift',
  TELEGRAM_CHAT: 'https://t.me/BlueshiftGroup',
  GITBOOK:
    'https://blueshift-fi.gitbook.io/product-docs/introduction/what-is-blueshift',
};

export const SOCIAL_LINKS: { [key: string]: string } = {
  TWITTER: 'https://twitter.com/blueshiftfi',
  TELEGRAM: 'https://t.me/BlueshiftGroup',
  MAIL: 'mailto:hello@blueshift.fi',
  GITHUB: 'https://github.com/blueshift-fi',
  DISCORD: 'https://t.co/zUNaFAd4o1',
};
