import { useWallet } from '@/composables/core/useWallet';
import { ERC20_ABI } from '@/constants/abi/ERC20_ABI';
import { bluesAddress, bluesDecimals } from '@/envinroment';
import { format } from '@/helpers/format';
import { TokenAmount } from '@/helpers/token-amount';
import BigNumber from 'bignumber.js';
import { computed, ref, watch } from 'vue';

const { web3, isNetworkError, address } = useWallet();

const bluesBalance = ref<TokenAmount | null>(null);

const bluesContract = computed(() => {
  if (web3.value && !isNetworkError.value) {
    return new web3.value.eth.Contract(ERC20_ABI, bluesAddress);
  }
  return null;
});

watch([address, isNetworkError], async ([address, isNetworkError]) => {
  if (address && !isNetworkError) {
    bluesBalance.value = await fetchBluesBalance();
  } else {
    bluesBalance.value = null;
  }
});

async function fetchBluesBalance(): Promise<TokenAmount> {
  if (!bluesContract.value) {
    throw new Error('Web3 is not initialized');
  }

  const balance = await bluesContract.value.methods
    .balanceOf(address.value)
    .call();

  return new TokenAmount(balance, bluesDecimals);
}

export function useBluesBalance() {
  async function updateBalance() {
    if (!bluesContract.value || isNetworkError.value) {
      return;
    }
    bluesBalance.value = null;
    bluesBalance.value = await fetchBluesBalance();
  }

  return {
    updateBalance,
    balanceFormatted: computed(() =>
      bluesBalance.value
        ? format(bluesBalance.value.relativeAmount as BigNumber)
        : '',
    ),
    balanceLoading: computed(() => !bluesBalance.value),
  };
}
