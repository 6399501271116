<template>
  <div
    :style="[
      size
        ? { width: size + 'px', height: size + 'px' }
        : { width: 'auto', height: 'auto' },
    ]"
    class="icon inline-flex align-items-center justify-content-center"
    v-html="getIconName()"
  ></div>
</template>

<script setup>
import { ICONS } from '@/constants/icons';

const props = defineProps({
  name: String,
  size: String,
});

const getIconName = () => ICONS[props.name];
</script>

<style scoped></style>
