import BigNumber from 'bignumber.js';
import { useWallet } from '@/composables/core/useWallet';

const { getFirstSupportedNetwork } = useWallet();

export function isChainSupported(chainId: string | number): boolean {
  const supportedChainIds =
    process.env.VUE_APP_SUPPORTED_NETWORK_IDS!.split(',');

  return supportedChainIds.some(id => new BigNumber(id).eq(chainId));
}

export const SUPPORTED_NETWORK_IDS =
  process.env.VUE_APP_SUPPORTED_NETWORK_IDS?.split(',').map(Number);

export function switchNetwork(
  netParams: typeof getFirstSupportedNetwork,
): void {
  (window as any).ethereum?.request({
    method: 'wallet_addEthereumChain',
    params: [netParams],
  });
}

interface TokenOptions {
  address: string;
  symbol: string;
  decimals: number;
  image?: string;
}

export function addToken(tokenOptions: TokenOptions): void {
  (window as any).ethereum?.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: tokenOptions,
    },
  });
}

export function toWei(
  value: string | number | BigNumber,
  decimals = 18,
): BigNumber {
  return new BigNumber(value).multipliedBy(10 ** decimals);
}

export function fromWei(
  value: string | number | BigNumber,
  decimals = 18,
): BigNumber {
  return new BigNumber(value).div(10 ** decimals);
}

export function unixTimeToDate(time: string | number): Date {
  return new Date(Number(time) * 1000);
}

export function secondsToMonths(seconds: string | number): number {
  const secondsInMonth = 2629743;
  return Math.ceil(Number(seconds) / secondsInMonth);
}
