import { PRICE_MULTIPLIER } from '@/constants/PRICE_MULTIPLIER';
import {
  apiBaseUrl,
  bluesAddress,
  bluesDecimals,
  usdTokenAddress,
  usdTokenDecimals,
} from '@/envinroment';
import { fromWei, toWei } from '@/helpers/blockchain-utils';
import BigNumber from 'bignumber.js';
import { computed, Ref, ref } from 'vue';

const bluesPrice: Ref<BigNumber | null> = ref(null);

async function fetchBluesPrice(): Promise<string> {
  const pricesRequest = await fetch(`${apiBaseUrl}pool/getPrices`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      signature: false,
      tokens: [bluesAddress],
      baseToken: usdTokenAddress,
    }),
  });

  const pricesResponse: { price: string[] } = await pricesRequest.json();
  return pricesResponse.price[0];
}

export function useBluesPrice() {
  async function updateBluesPrice() {
    bluesPrice.value = null;
    const bluesWeiPerUSDCWeiPrice = new BigNumber(await fetchBluesPrice()).div(PRICE_MULTIPLIER);
    const bluesRelativePerUSDCWeiPrice = toWei(
      bluesWeiPerUSDCWeiPrice,
      bluesDecimals,
    );
    bluesPrice.value = fromWei(bluesRelativePerUSDCWeiPrice, usdTokenDecimals);
  }

  return {
    bluesPrice,
    isBluesPriceLoading: computed(() => !bluesPrice.value),
    updateBluesPrice,
  };
}
