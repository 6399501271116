import { walletsOptions } from '@/helpers/wallet-options';
import Menu from 'primevue/menu';
import router from '@/views/router';
import Skeleton from 'primevue/skeleton';
import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import { LockPlugin } from '@snapshot-labs/lock/plugins/vue3';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import OverlayPanel from 'primevue/overlaypanel';
import Divider from 'primevue/divider';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ProgressBar from 'primevue/progressbar';

const app = createApp(App)
  .use(PrimeVue)
  .use(LockPlugin, walletsOptions)
  .use(ToastService)
  .use(router);

app.component('Button', Button);
app.component('Dialog', Dialog);
app.component('OverlayPanel', OverlayPanel);
app.component('Divider', Divider);
app.component('ProgressBar', ProgressBar);
app.component('Toast', Toast);
app.component('Skeleton', Skeleton);
app.component('Menu', Menu);

app.mount('#app');

export default app;
