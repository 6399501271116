import {
  isChainSupported,
  SUPPORTED_NETWORK_IDS,
} from '@/helpers/blockchain-utils';
import networks from '@/constants/networks.json';
import { Network } from '@/models/network';
import Connector from '@snapshot-labs/lock/dist/connector';
import { computed, reactive, ref } from 'vue';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import Web3 from 'web3';
import configuredConnectors from '@/constants/connectors.json';

interface WalletStateConditional<WalletConnected extends boolean> {
  address: WalletConnected extends true ? string : '';
  connector: WalletConnected extends true ? Connector : null;
  network: Network | null;
  web3: WalletConnected extends true ? Web3 : null;
  authProcess: boolean;
  balance: number;
}

type WalletState = WalletStateConditional<true> | WalletStateConditional<false>;

const defaultNetworkId: string =
  process.env.VUE_APP_DEFAULT_NETWORK_ID || Object.keys(networks)[0];

const walletState: WalletState = reactive({
  address: '',
  network: networks[defaultNetworkId],
  connector: null,
  web3: null,
  authProcess: false,
  balance: 0,
});

const isNetworkError = ref<boolean | null>(null);

export function useWallet() {
  async function checkLocalStorageAndDoAutoLogin(): Promise<void> {
    const connector = getInstance();
    const providerName = await connector.getConnector();
    if (providerName) {
      await login(providerName);
    }
  }

  async function login(providerName = 'injected'): Promise<void> {
    walletState.authProcess = true;
    try {
      const connectionManager = getInstance();
      await connectionManager.login(providerName);
      await new Web3(connectionManager.provider.value).eth.getAccounts();

      connectionManager.provider.value.on?.('chainChanged', processConnection);

      connectionManager.provider.value.on?.(
        'accountsChanged',
        processConnection,
      );

      await processConnection();
    } catch (e) {
      console.error(e);
      throw e;
    }
    walletState.authProcess = false;
  }

  async function processConnection(): Promise<void> {
    const connectionManager = getInstance();

    const connectorName = await connectionManager.getConnector();
    walletState.connector = configuredConnectors[connectorName];
    walletState.web3 = new Web3(connectionManager.provider.value);
    const userAddress = (await walletState.web3.eth.getAccounts())[0];

    if (!userAddress) {
      return logout();
    }

    const selectedChainId = await walletState.web3.eth.getChainId();
    isNetworkError.value = !isChainSupported(selectedChainId);
    walletState.network = networks[selectedChainId.toString()];

    const walletBalance = await walletState.web3.eth.getBalance(userAddress);
    walletState.balance = +walletState.web3.utils.fromWei(walletBalance);

    walletState.address = userAddress;
  }

  function getFirstSupportedNetwork() {
    let net = networks[defaultNetworkId];
    if (SUPPORTED_NETWORK_IDS) {
      net = networks[SUPPORTED_NETWORK_IDS[0]];
    }
    const chainId = +net.key;
    const chainIdHec = '0x' + chainId.toString(16);

    return {
      chainId: chainIdHec,
      rpcUrls: net.rpc,
      chainName: net.shortName,
      nativeCurrency: net.nativeCurrency,
      blockExplorerUrls: [net.explorer],
    };
  }

  function logout(): void {
    const connector = getInstance();
    connector.provider.value.removeAllListeners?.();
    connector.logout();
    walletState.connector = null;
    walletState.web3 = null;
    walletState.address = '';
    walletState.network = null;
    isNetworkError.value = false;
  }

  return {
    checkLocalStorageAndDoAutoLogin,
    login,
    getFirstSupportedNetwork,
    logout,
    isInjected: computed(() => walletState.address !== ''),
    web3: computed(() => walletState.web3),
    address: computed(() => walletState.address),
    connector: computed(() => walletState.connector),
    authProcess: computed(() => walletState.authProcess),
    isNetworkError,
    network: computed(() => walletState.network),
    balance: computed(() => walletState.balance),
  };
}
