<template>
  <div
    class="flex align-items-center gutter-x-s5 xl:gutter-x-s4 justify-content-center sm:justify-content-end"
  >
    <a
      v-for="(item, index) in socials"
      :key="'social' + index"
      :href="item.link"
      target="_blank"
    >
      <Icon
        :name="item.name.toLowerCase()"
        size="20"
        class="social-icon m-s2"
      />
    </a>
  </div>
</template>

<script setup>
import Icon from '@/components/Icon';
import { SOCIAL_LINKS } from '@/constants/EXTERNAL_LINKS';

const socials = [
  {
    name: 'Twitter',
    link: SOCIAL_LINKS.TWITTER,
  },
  {
    name: 'Telegram',
    link: SOCIAL_LINKS.TELEGRAM,
  },
  {
    name: 'mail',
    link: SOCIAL_LINKS.MAIL,
  },
  {
    name: 'GitHub',
    link: SOCIAL_LINKS.GITHUB,
  },
  {
    name: 'Discord',
    link: SOCIAL_LINKS.DISCORD,
  },
];
</script>
<style scoped lang="scss">
.social-icon {
  color: var(--text-muted);
  cursor: pointer;
  &:hover {
    color: var(--primary);
    opacity: 0.7;
  }
}
</style>
