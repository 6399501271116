import { computed, reactive } from 'vue';

const layout = reactive({
  isMobile: false,
  isTablet: false,
  isDesktop: true,
  view: 'desktop',
  isLoading: false,
});

export function useLayout() {
  function setLayout() {
    const cls = ['mobile', 'tablet', 'desktop'];
    if (window.innerWidth < 576) {
      layout.isMobile = true;
      layout.isTablet = false;
      layout.isDesktop = false;
      layout.view = 'mobile';
    } else {
      if (window.innerWidth >= 576 && window.innerWidth < 1200) {
        layout.isTablet = true;
        layout.isMobile = false;
        layout.isDesktop = false;
        layout.view = 'tablet';
      } else {
        layout.isDesktop = true;
        layout.isTablet = false;
        layout.isMobile = false;
        layout.view = 'desktop';
      }
    }
    document.body.classList.remove(...cls);
    document.body.classList.add(layout.view);
  }

  function setLoading(loading) {
    layout.isLoading = loading;
  }

  function openLink(url) {
    window.open(url, '_blank');
  }

  return {
    setLayout,
    setLoading,
    openLink,
    isMobile: computed(() => layout.isMobile),
    isTablet: computed(() => layout.isTablet),
    isDesktop: computed(() => layout.isDesktop),
    layout: computed(() => layout.view),
    isLoading: computed(() => layout.isLoading),
  };
}
